footer p {
  color: white;
}
footer .header div {
  padding: 0px;
}
footer .header .social-networks {
  font-size: 2.5rem;
}
footer .header .social-networks a {
  text-decoration: inherit;
  color: inherit;
}
footer .header .social-networks a:last-child {
  margin-left: 1rem;
}

.bd-footer {
  margin-top: 2rem;
  font-size: 85%;
  text-align: center;
  background-color: #102636;
  padding: 4rem 0px;
  color: white;
}

@media (min-width: 576px) {
  .bd-footer {
    text-align: left;
  }
}

