@import 'styles/_global.scss';

footer{
  p {
    color: white;
  }
  .header{
    div{
      padding: 0px;
    }
    .social-networks{
      font-size: 2.5rem;
      
      a{
        text-decoration: inherit;
        color:inherit;
      }

      a:last-child{
        margin-left: 1rem;
      }
    }
  }
}

.bd-footer {
  margin-top: 2rem;
  font-size: 85%;
  text-align: center;
  background-color: $main-dark;
  padding: 4rem 0px;
  color: white;
}

@media (min-width: 576px) {
  .bd-footer {
    text-align: left;
  }
}
